.background-image-text {
  background-color: #f2f2f2;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.text-container-text {
  display: flex;
  align-items: center;
  justify-content: center;
}
/*### Big Screen CSS Here ### */

.subtitle-short-text {
  color: #41d2bf !important;
  font-weight: 700 !important;
  letter-spacing: -0.05em !important;
  font-size: 70px !important;
  line-height: 85px !important;
  opacity: 0;
  transform: translateY(15px);
  transition: opacity 0.5s ease-out, transform 0.5s ease-out;
}

.subtitle-short-text.animate {
  opacity: 1;
  transform: translateY(0);
  transition: opacity 1s ease-in-out 0.3s, transform 1s ease-in-out 0.3s;
  transition-delay: 1s;
  color: #41d2bf !important;
}

.title-short-text {
  color: #000000 !important;
  font-weight: 700 !important;
  letter-spacing: -0.05em !important;
  font-size: 70px !important;
  line-height: 85px !important;
  opacity: 0;
  transition: opacity 0.5s ease-out, transform 0.5s ease-out;
}

.title-short-text.animate {
  opacity: 1;
  transition: opacity 1s ease-in-out 0.3s, transform 1s ease-in-out 0.3s;
  transition-delay: 0.3s;
}
/*### Desktop Screen CSS Here ### */
@media screen and (max-width: 1200px) {
  .title-short-text {
    font-size: 55px !important;
    line-height: 60px !important;
  }

  .subtitle-short-text {
    font-size: 55px !important;
    line-height: 60px !important;
  }
}

/*### Laptop Screen CSS Here ### */
@media screen and (max-width: 1024px) {
  .title-short-text {
    font-size: 50px !important;
    line-height: 55px !important;
  }

  .subtitle-short-text {
    font-size: 50px !important;
    line-height: 55px !important;
  }
}

/*### Tablet Screen CSS Here ### */
@media screen and (max-width: 768px) {
  .title-short-text {
    font-size: 30px !important;
    line-height: 38px !important;
  }

  .subtitle-short-text {
    font-size: 30px !important;
    line-height: 38px !important;
  }
}

/*### Phone Screen CSS Here ### */
@media only screen and (max-width: 480px) {
  .title-short-text {
    font-size: 23px !important;
    line-height: 30px !important;
  }

  .subtitle-short-text {
    font-size: 23px !important;
    line-height: 30px !important;
  }
}
