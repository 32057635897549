.background-image-about {
  background-color: #262626;
}

.grid-container {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

/*### Big Screen CSS Here ### */
.text-container-about {
  padding-top: 230px !important;
  padding-bottom: 215px !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

.text-about {
  color: #fefefe !important;
  font-weight: 400 !important;
  font-size: 30px !important;
  line-height: 40px !important;
  letter-spacing: -0.05em !important;
  word-wrap: break-word !important;
}

.img-about {
  height: 304px !important;
  width: 304px !important;
  margin-right: 197px;
  opacity: 0;
  transform: translateX(-50px);
  transition: opacity 0.5s ease-out, transform 0.5s ease-out;
}

.img-about.animate {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 1s ease-in-out 0.3s, transform 1s ease-in-out 0.3s;
}

.text-holder {
  width: 540px;
  opacity: 0;

  transition: opacity 0.5s ease-out, transform 0.5s ease-out;
}

.text-holder.animateText {
  transition: opacity 1s ease-in-out 0.3s, transform 1s ease-in-out 0.3s;
  opacity: 1;
}

/*### Desktop Screen CSS Here ### */
@media screen and (max-width: 1200px) {
  .text-about {
    color: #fefefe !important;
    font-weight: 400 !important;
    font-size: 30px !important;
    line-height: 40px !important;
    letter-spacing: -0.05em !important;
    word-wrap: break-word !important;
  }

  .img-about {
    height: 304px !important;
    width: 304px !important;
    margin-right: 100px;
  }

  .text-holder {
    width: 540px;
  }
}

/*### Laptop Screen CSS Here ### */
@media screen and (max-width: 1024px) {
  .text-about {
    font-size: 23px !important;
    line-height: 30px !important;
  }

  .img-about {
    height: 254px !important;
    width: 254px !important;
    margin-right: 70px !important;
  }

  .text-holder {
    width: 300px;
  }

  .text-container-about {
    padding-top: 150px !important;
    padding-bottom: 150px !important;
  }
}

/*### Tablet Screen CSS Here ### */
@media screen and (max-width: 768px) {
  .text-container-previous {
    padding-top: 100px !important;
    padding-bottom: 100px !important;
  }
  .text-about {
    font-size: 30px !important;
    line-height: 40px !important;
    /* font-size: 23px !important;
    line-height: 35px !important; */
    text-align: center !important;
  }

  .img-about {
    height: 190px !important;
    width: 190px !important;
    margin-bottom: 30px !important;
    margin-right: 0px !important;
  }

  .text-holder {
    width: 80%;
  }
}

/*### Phone Screen CSS Here ### */
@media only screen and (max-width: 480px) {
  .text-container-previous {
    padding-top: 50px !important;
    padding-bottom: 50px !important;
  }

  .text-about {
    font-size: 20px !important;
    line-height: 30px !important;
  }

  .img-about {
    height: 150px !important;
    width: 150px !important;
    margin-right: 0px !important;
    margin-bottom: 30px !important;
  }

  /* .text-holder {
    width: 270px;
  } */

  .text-container-about {
    padding-top: 100px !important;
    padding-bottom: 80px !important;
  }
}
