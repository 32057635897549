.background-image-contact {
  background-color: #262626;
}

.grid-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

/*### Big Screen CSS Here ### */
.text-container-contact {
  padding-top: 230px;
  padding-bottom: 230px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.image-contact {
  width: 500px !important;
  height: 500px !important;
}

.text-contact {
  color: #fefefe;
  font-weight: 400;
  font-size: 30px !important;
  line-height: 40px !important;
  letter-spacing: -0.05em;
}

.button-contact {
  width: 350px !important;
  height: 40px !important;
  font-size: 23px !important;
  line-height: 25px !important;
}

.icon-contact {
  color: #41d2bf;
  font-size: 30px !important;
}

.contact-image {
  opacity: 0;
  transform: translateX(-50px);
  transition: opacity 1s ease-out, transform 1s ease-out;
}
.contact-image.animate1 {
  opacity: 1;
  transform: translateY(0);
  transition-delay: 0.7s !important;
}

.contact-text {
  opacity: 0;
  transform: translateX(50px);
  transition: opacity 1s ease-out, transform 1s ease-out;
}
.contact-text.animate2 {
  opacity: 1;
  transform: translateY(0);
  transition-delay: 0.7s !important;
}

/*### Desktop Screen CSS Here ### */
@media screen and (max-width: 1200px) {
  /*No changes*/
}

/*### Laptop Screen CSS Here ### */
@media screen and (max-width: 1024px) {
  .image-contact {
    width: 400px !important;
    height: 400px !important;
  }

  .text-contact {
    font-size: 23px !important;
    line-height: 35px !important;
  }

  .button-contact {
    width: 250px !important;
    height: 40px !important;
    font-size: 18px !important;
    line-height: 22px !important;
  }

  .icon-contact {
    font-size: 25px !important;
  }
}

/*### Tablet Screen CSS Here ### */
@media screen and (max-width: 768px) {
  .text-container-contact {
    padding-top: 140px !important;
    padding-bottom: 140px !important;
  }

  .image-contact {
    width: 300px !important;
    height: 300px !important;
  }

  .text-contact {
    font-size: 20px !important;
    line-height: 28px !important;
  }

  .button-contact {
    font-size: 16px !important;
    line-height: 20px !important;
  }
}

/*### Phone Screen CSS Here ### */
@media only screen and (max-width: 480px) {
  .text-container-contact {
    padding-top: 50px !important;
    padding-bottom: 50px !important;
  }

  .image-contact {
    width: 200px !important;
    height: 200px !important;
  }

  .text-contact {
    font-size: 15px !important;
    line-height: 25px !important;
  }

  .button-contact {
    width: 180px !important;
    height: 30px !important;
    font-size: 14px !important;
    line-height: 18px !important;
  }

  .icon-contact {
    font-size: 20px !important;
  }
}
