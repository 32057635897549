.background-image-services {
  background-color: #262626;
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.text-container-services {
  padding-top: 230px;
  padding-bottom: 215px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.main-grid {
  direction: row;
  justify-content: center;
  align-items: center;
}

.picture-icon {
  width: 70px;
  height: 70px;
  opacity: 0.9;
}

.service-title {
  margin-top: 30px !important;
  font-weight: 700 !important;
  font-size: 25px !important;
  line-height: 25px !important;
  text-align: center !important;
  letter-spacing: -0.05em !important;
  color: #41d2bf !important;
  margin-bottom: 30px !important;
  opacity: 0.9;
}

.service-text {
  word-wrap: break-word !important;
  font-weight: 300 !important;
  font-size: 21px !important;
  line-height: 30px !important;
  text-align: center !important;
  letter-spacing: 0.1em !important;
  color: #fefefe !important;
}

.grid-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.service-item {
  border-radius: 10px !important;
  max-width: 500px !important;
  min-height: 520px !important;
  padding: 30px;
  transition: background-color 0.4s ease-in, filter 0.4s ease-in;
}

.service-item:hover {
  background-color: #3f3f3f;
  opacity: 1;
  filter: drop-shadow(18px 18px 5px #1f1f1f);
}

.service-container {
  opacity: 0;
  transform: translateY(50px);
  transition: opacity 1s ease-out, transform 1s ease-out;
}

.service-container.animate1 {
  opacity: 1;
  transform: translateY(0);
  transition-delay: 0.5s !important;
}

.service-container.animate2 {
  opacity: 1;
  transform: translateY(0);
  transition-delay: 1s !important;
}

.service-container.animate3 {
  opacity: 1;
  transform: translateY(0);
  transition-delay: 1.5s !important;
}

/*### Desktop Screen CSS Here ### */
@media screen and (max-width: 1200px) {
  /* Nema izmena */
}

/*### Laptop Screen CSS Here ### */
@media screen and (max-width: 1024px) {
  .text-container-services {
    padding-top: 150px;
    padding-bottom: 150px;
  }
}

/*### Tablet Screen CSS Here ### */
@media screen and (max-width: 768px) {
  .service-container.animate1 {
    transition-delay: 0.5s !important;
  }

  .service-container.animate2 {
    transition-delay: 0.5s !important;
  }

  .service-container.animate3 {
    transition-delay: 0.5s !important;
  }
}

/*### Phone Screen CSS Here ### */
@media screen and (max-width: 480px) {
  .service-item {
    min-height: 430px !important;
  }

  .text-container-services {
    padding-top: 80px;
    padding-bottom: 100px;
  }

  .service-text {
    font-size: 20px !important;
    line-height: 25px !important;
    letter-spacing: -0.03em !important;
  }
}
