.background-image-loading {
  background-color: #262626;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  align-content: center;
}

.logo {
  display: block;
  width: 15%;
  margin-left: auto;
  margin-right: auto;
  -webkit-filter: drop-shadow(12px 12px 7px rgba(0, 0, 0, 0.5));
  filter: drop-shadow(12px 12px 7px rgba(0, 0, 0, 0.5));
}

@media screen and (max-width: 1440px) {
  .logo {
    width: 20%;
  }
}

@media screen and (max-width: 1024px) {
}

@media screen and (max-width: 768px) {
}

@media screen and (max-width: 480px) {
  .logo {
    width: 30%;
  }
}
