.border-item {
  width: calc(100% / 3);
}

.background-image-footer {
  background-color: #262626;
  padding-top: 10px;
  border-top: solid 2px #41d2bf;
  display: flex;
  align-items: center;
  justify-content: center;
}

/*### Big Screen CSS Here ### */
.footer-container {
  margin-left: 40px !important;
  margin-right: 40px !important;
  margin-top: 20px !important;
}

.footer-logo-container {
  width: 320px !important;
}

.footer-logo {
  width: 100px !important;
}

.footer-logo-text {
  font-weight: 400 !important;
  letter-spacing: 0.13em !important;
  font-size: 12px !important;
  line-height: 20px !important;
  color: #fefefe !important;
  width: 200px !important;
  text-align: start !important;
  word-wrap: break-word !important;
  margin-left: 15px !important;
}

.footer-border-container {
  width: 600px;
  border-right: 1px solid #fefefe;
  border-left: 1px solid #fefefe;
}

.footer-learn-container {
  margin-left: 30px !important;
}

.footer-learn-text {
  font-weight: 400 !important;
  letter-spacing: 0.13em !important;
  font-size: 15px !important;
  line-height: 30px !important;
  color: #41d2bf !important;
}

.footer-learn-appointment {
  font-weight: 400 !important;
  letter-spacing: 0.13em !important;
  font-size: 15px !important;
  line-height: 30px !important;
  color: #fefefe !important;
  word-wrap: break-word;
}

.footer-learn-mail {
  font-weight: 200 !important;
  letter-spacing: 0.13em !important;
  font-size: 15px !important;
  line-height: 30px !important;
  color: #fefefe !important;
}

.footer-copy-container {
  width: 150px !important;
  margin-left: 30px !important;
}

.footer-copy-text {
  font-weight: 400 !important;
  letter-spacing: 0.13em !important;
  font-size: 12px !important;
  line-height: 20px !important;
  color: #fefefe !important;
  text-align: end !important;
}

/*### Desktop Screen CSS Here ### */
@media screen and (max-width: 1200px) {
  .footer-container {
    margin-left: 30px !important;
    margin-right: 30px !important;
    margin-top: 15px !important;
  }

  .footer-logo-container {
    width: 220px !important;
  }

  .footer-logo {
    width: 50px !important;
  }

  .footer-logo-text {
    font-size: 10px !important;
    line-height: 15px !important;
    width: 150px !important;
  }

  .footer-border-container {
    width: 400px;
  }

  .footer-learn-container {
    margin-left: 20px !important;
  }

  .footer-learn-text {
    font-size: 10px !important;
    line-height: 15px !important;
  }

  .footer-learn-appointment {
    font-size: 10px !important;
    line-height: 15px !important;
  }

  .footer-learn-mail {
    font-size: 10px !important;
    line-height: 15px !important;
  }

  .footer-copy-container {
    width: 150px !important;
    margin-left: 20px !important;
  }

  .footer-copy-text {
    font-size: 10px !important;
    line-height: 15px !important;
  }
}

/*### Laptop Screen CSS Here ### */
@media screen and (max-width: 1024px) {
  .footer-container {
    margin-left: 15px !important;
    margin-right: 15px !important;
    margin-top: 15px !important;
  }

  .footer-logo-container {
    width: 220px !important;
  }

  .footer-logo {
    width: 50px !important;
  }

  .footer-logo-text {
    font-size: 10px !important;
    line-height: 15px !important;
    width: 100px !important;
  }

  .footer-border-container {
    width: 350px;
  }

  .footer-learn-container {
    margin-left: 10px !important;
  }

  .footer-learn-text {
    font-size: 10px !important;
    line-height: 15px !important;
  }

  .footer-learn-appointment {
    font-size: 10px !important;
    line-height: 15px !important;
  }

  .footer-learn-mail {
    font-size: 10px !important;
    line-height: 15px !important;
  }

  .footer-copy-container {
    width: 150px !important;
    margin-left: 10px !important;
  }

  .footer-copy-text {
    font-size: 10px !important;
    line-height: 15px !important;
  }
}

/*### Tablet Screen CSS Here ### */
@media screen and (max-width: 768px) {
  .footer-container {
    margin-left: 5px !important;
    margin-right: 5px !important;
    margin-top: 15px !important;
  }

  .footer-logo-container {
    width: 90px !important;
  }

  .footer-logo {
    width: 25px !important;
  }

  .footer-logo-text {
    font-size: 7px !important;
    line-height: 12px !important;
    width: 50px !important;
  }

  .footer-border-container {
    width: 320px;
  }

  .footer-learn-container {
    margin-left: 5px !important;
  }

  .footer-learn-text {
    font-size: 7px !important;
    line-height: 12px !important;
  }

  .footer-learn-appointment {
    font-size: 7px !important;
    line-height: 12px !important;
  }

  .footer-learn-mail {
    font-size: 7px !important;
    line-height: 12px !important;
  }

  .footer-copy-container {
    width: 40px !important;
    margin-left: 5px !important;
  }

  .footer-copy-text {
    font-size: 7px !important;
    line-height: 12px !important;
  }
}

/*### Phone Screen CSS Here ### */
@media only screen and (max-width: 480px) {
  .footer-border-container {
    border-right: 0px !important;
    border-left: 0px !important;
    width: 100% !important;
  }

  .footer-learn-container {
    align-items: center;
    text-align: center;
  }

  .footer-copy-container {
    width: 100% !important;
    text-align: center !important;
  }

  .footer-logo-container {
    width: 100% !important;
  }

  .footer-logo-text {
    width: 100% !important;
    line-height: 10px !important;
    margin-bottom: 30px !important;
    margin-left: 20px !important;
  }

  .footer-learn-text {
    font-size: 13px !important;
    line-height: 15px !important;
  }

  .footer-learn-appointment {
    font-size: 13px !important;
    line-height: 15px !important;
    margin-bottom: 15px !important;
  }

  .footer-learn-mail {
    font-size: 17px !important;
    line-height: 17px !important;
    margin-bottom: 30px !important;
  }

  .footer-copy-text {
    font-size: 8px !important;
    line-height: 11px !important;
    text-align: center !important;
    margin-bottom: 15px !important;
  }

  .footer-logo {
    width: 70px !important;
  }

  .footer-logo-text {
    font-size: 13px !important;
    line-height: 15px !important;
  }
}
