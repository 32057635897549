/*Pozadina stranice*/
.background-image-previous {
  background-color: #f2f2f2;
}

/*Div ciji je kontent centriran i u kom se nalazi nas grid*/
.grid-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

/*Kljucni momenti za animacije fade-in i fade-out*/
@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fade-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

/* Kljucni momenti za animacije fade-in i fade-out ZA OKO BUTTON */
@keyframes fade-in-btn {
  from {
    opacity: 0.5;
  }
  to {
    opacity: 1;
  }
}
@keyframes fade-out-btn {
  from {
    opacity: 1;
  }
  to {
    opacity: 0.5;
  }
}

/*Slike portfolio projekata*/
.background-1 {
  border-radius: 10px;
  background-image: url(../../public/doroPreview.png);
  background-size: cover;
  background-position: center center;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  position: relative;
  transition: transform 0.5s ease-in-out;
}
.background-2 {
  border-radius: 10px;
  background-image: url(../../public/gdPreview.png);
  background-size: cover;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  position: relative;
  transition: transform 0.5s ease-in-out;
}
.background-3 {
  border-radius: 10px;
  background-image: url(../../public/divaPreview.png);
  background-size: cover;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  position: relative;
  transition: transform 0.5s ease-in-out;
}
.background-4 {
  border-radius: 10px;
  background-image: url(../../public/bookkeeping.webp);
  background-size: cover;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  position: relative;
  transition: transform 0.5s ease-in-out;
}

/*Div koji se prikazuje/sklanja na hover misem*/
.notdisplayed {
  opacity: 1;
  visibility: visible;
  transition: opacity 0.5s ease-in-out;
}
.displayed {
  display: block;
}

/*Crni sloj koji ide preko slike na hover*/
.displayed-layer {
  background-color: rgba(38, 38, 38, 0.9);
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: background-color 0.5s ease-in-out;
  border-radius: 10px;
}
.displayed-layer:hover {
  background-color: rgba(38, 38, 38, 0.9);
}
.notdisplayed-layer {
  background-color: rgba(38, 38, 38, 0);
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: background-color 0.5s ease-in-out;
  border-radius: 10px;
}

/*### Big Screen CSS Here ### */

/*Container u kom se nalazi content na stranici*/
.text-container-previous {
  padding-top: 230px !important;
  padding-bottom: 230px !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

/*Div u kom se nalazi slika projekta i kontent koji se prikazuje na hover*/
.item-container-previous-levo {
  border-radius: 10px;
  height: 518px !important;
  width: 600px !important;
  padding: 50px !important;
  opacity: 0;
  transform: translateX(-50px);
  transition: opacity 1s ease-out, transform 1s ease-out;
}

.item-container-previous-desno {
  border-radius: 10px;
  height: 518px !important;
  width: 600px !important;
  padding: 50px !important;
  opacity: 0;
  transform: translateX(50px);
  transition: opacity 1s ease-out, transform 1s ease-out;
}

.item-container-previous-levo.animate1 {
  opacity: 1;
  transform: translateY(0);
  transition-delay: 0.7s !important;
}

.item-container-previous-levo.animate2 {
  opacity: 1;
  transform: translateY(0);
  transition-delay: 0.7s !important;
}

.item-container-previous-desno.animate3 {
  opacity: 1;
  transform: translateY(0);
  transition-delay: 0.7s !important;
}

.item-container-previous-desno.animate4 {
  opacity: 1;
  transform: translateY(0);
  transition-delay: 0.7s !important;
}

/*Div koji se prikazuje na hover*/
.item-container-overlay {
  height: 418px !important;
  width: 500px !important;
  margin: auto !important;
}

/*Dugme koje se nalazi u divu koji se prikazuje na hover*/
.displayed-button {
  /* height: 42px !important;*/
  margin-top: 20px !important;
  font-size: 20px !important;
  line-height: 22px !important;
  animation: fade-in-btn 0.5s ease-in-out forwards;
  opacity: 0.5;
}
.notdisplayed-button {
  /* width: 118px !important; */
  /* height: 42px !important; */
  margin-top: 20px !important;
  font-size: 20px !important;
  line-height: 22px !important;
  animation: fade-out-btn 0.5s ease-in-out forwards;
  opacity: 1;
  pointer-events: none;
}

.displayed-icon {
  font-size: 50px !important;
}

/*Tekst koji se nalazi u divu koji se prikazuje na hover*/
.displayed-text {
  font-weight: 400;
  font-size: 23px !important;
  line-height: 32px !important;
  text-align: center;
  letter-spacing: 0.13em;
  width: 440px !important;
  color: #fefefe;
  word-wrap: break-word;
  animation: fade-in 0.5s ease-in-out forwards;
  opacity: 0;
}
.notdisplayed-text {
  font-weight: 400;
  font-size: 23px !important;
  line-height: 32px !important;
  text-align: center;
  letter-spacing: 0.13em;
  width: 440px !important;
  color: #fefefe;
  word-wrap: break-word;
  animation: fade-out 0.5s ease-in-out forwards;
  opacity: 1;
}

/*### Desktop Screen CSS Here ### */
@media screen and (max-width: 1200px) {
  .item-container-previous-levo {
    padding: 40px !important;
    height: 407px !important;
    width: 499px !important;
  }

  .item-container-previous-desno {
    padding: 40px !important;
    height: 407px !important;
    width: 499px !important;
  }

  .item-container-overlay {
    height: 327px !important;
    width: 419px !important;
  }

  .displayed-icon {
    font-size: 40px !important;
  }

  .displayed-button {
    /* height: 32px !important; */
    font-size: 20px !important;
    line-height: 21px !important;
  }
  .notdisplayed-button {
    /* height: 32px !important; */
    font-size: 20px !important;
    line-height: 21px !important;
  }

  .displayed-text {
    font-size: 20px !important;
    line-height: 22px !important;
    width: 300px !important;
  }
  .notdisplayed-text {
    font-size: 20px !important;
    line-height: 22px !important;
    width: 300px !important;
  }
}

/*### Laptop Screen CSS Here ### */
@media screen and (max-width: 1024px) {
  .item-container-previous-levo.animate1 {
    transition-delay: 0.5s !important;
  }

  .item-container-previous-levo.animate2 {
    transition-delay: 1s !important;
  }

  .item-container-previous-desno.animate3 {
    transition-delay: 1s !important;
  }

  .item-container-previous-desno.animate4 {
    transition-delay: 1s !important;
  }
  .item-container-previous-levo {
    padding: 27px !important;
    height: 282px !important;
    width: 379px !important;
  }

  .item-container-previous-desno {
    padding: 27px !important;
    height: 282px !important;
    width: 379px !important;
  }

  .item-container-overlay {
    height: 228px !important;
    width: 325px !important;
  }

  .displayed-text {
    font-size: 17px !important;
    line-height: 19px !important;
    width: 330px !important;
  }
  .notdisplayed-text {
    font-size: 17px !important;
    line-height: 19px !important;
    width: 330px !important;
  }
}

/*### Tablet Screen CSS Here ### */
@media screen and (max-width: 768px) {
  .displayed-icon {
    font-size: 30px !important;
  }

  .text-container-previous {
    padding-top: 140px !important;
    padding-bottom: 140px !important;
  }

  .item-container-previous-levo {
    padding: 30px !important;
    height: 337px !important;
    width: 429px !important;
  }

  .item-container-previous-desno {
    padding: 30px !important;
    height: 337px !important;
    width: 429px !important;
  }

  .item-container-overlay {
    height: 277px !important;
    width: 369px !important;
  }

  .displayed-button {
    /* height: 30px !important; */
    font-size: 18px !important;
    line-height: 20px !important;
  }
  .notdisplayed-button {
    /* height: 30px !important; */
    font-size: 18px !important;
    line-height: 20px !important;
  }

  .displayed-text {
    font-size: 17px !important;
    line-height: 19px !important;
    width: 250px !important;
  }
  .notdisplayed-text {
    font-size: 17px !important;
    line-height: 19px !important;
    width: 250px !important;
  }
}

/*### Phone Screen CSS Here ### */
@media only screen and (max-width: 480px) {
  .text-container-previous {
    padding-top: 50px !important;
    padding-bottom: 50px !important;
  }

  .item-container-previous-levo {
    padding: 25px !important;
    height: 300px !important;
    width: 392px !important;
  }

  .item-container-previous-desno {
    padding: 25px !important;
    height: 300px !important;
    width: 392px !important;
  }

  .item-container-overlay {
    height: 250px !important;
    width: 342px !important;
  }

  .displayed-button {
    /* height: 21px !important; */
    font-size: 15px !important;
    line-height: 17px !important;
  }
  .notdisplayed-button {
    /* height: 21px !important; */
    font-size: 15px !important;
    line-height: 17px !important;
  }

  .displayed-text {
    font-size: 15px !important;
    line-height: 17px !important;
    width: 250px !important;
  }

  .notdisplayed-text {
    font-size: 15px !important;
    line-height: 17px !important;
    width: 250px !important;
  }
}
