/*### Big Screen CSS Here ### */
.text-underline {
  font-weight: 700 !important;
  font-size: 50px !important;
  line-height: 80px !important;
  letter-spacing: -0.05em !important;
  position: relative;
  display: inline-block;
}

.text-underline::after {
  content: "";
  display: block;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 0;
  height: 3px;
  background-color: #41d2bf;
  transition: width 0.5s ease-out;
}

.text-container {
  text-align: center !important;
  margin-bottom: 80px !important;
}

.text-underline.animate::after {
  width: 50%;
  transition: width 1s ease-in-out;
}

/*### Desktop Screen CSS Here ### */
@media screen and (max-width: 1200px) {
  /*No changes*/
}

/*### Laptop Screen CSS Here ### */
@media screen and (max-width: 1024px) {
  .text-underline {
    font-size: 50px !important;
    line-height: 60px !important;
  }

  /* .text-container {
    margin-bottom: 80px !important;
  } */
}

/*### Tablet Screen CSS Here ### */
@media screen and (max-width: 768px) {
  .text-underline {
    font-size: 35px !important;
    line-height: 55px !important;
  }

  .text-container {
    margin-bottom: 50px !important;
  }
}

/*### Phone Screen CSS Here ### */
@media only screen and (max-width: 480px) {
  .text-underline {
    font-size: 30px !important;
    line-height: 42px !important;
  }

  /* .text-container {
    margin-bottom: 50px !important;
  } */
}
