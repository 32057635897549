/*### Big Screen CSS Here ### */

@keyframes slideIn {
  from {
    opacity: 0;
    transform: translateY(-30px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fade-in {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.background-image-home {
  background-image: url(../../public/hero-background.png);
  height: 100vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.text-container-home {
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
}

.text-container-hero {
  margin-left: 98px !important;
}

.subtitle-container-hero {
  margin-left: 290px !important;
  margin-top: "52px" !important;
}

.text-title-hero {
  color: #41d2bf !important;
  font-weight: 700 !important;
  font-size: 70px !important;
  letter-spacing: -0.05em !important;
  line-height: 70px !important;
  animation: slideIn 0.8s ease-out;
  animation-delay: 0.3s;
  opacity: 0;
  transform: translateY(-30px);
  animation-fill-mode: forwards;
}

.text-subtitle-hero {
  color: #fefefe !important;
  font-weight: 700 !important;
  font-size: 70px !important;
  letter-spacing: -0.05em !important;
  line-height: 70px !important;
  animation: slideIn 0.8s ease-out;
  animation-delay: 0.3s;
  opacity: 0;
  transform: translateY(-30px);
  animation-fill-mode: forwards;
}

.text-slogan-hero {
  color: #fefefe !important;
  font-weight: 300 !important;
  letter-spacing: 0.1em !important;
  line-height: 31px !important;
  font-size: 25px !important;
  padding-right: 20px !important;
  margin-top: 20px !important;
  opacity: 0;
  animation: fade-in 1s ease-in-out forwards;
  animation-delay: 1s;
}

/*### Desktop Screen CSS Here ### */
@media screen and (max-width: 1200px) {
  .text-container-hero {
    margin-left: 90px !important;
  }

  .subtitle-container-hero {
    margin-left: 282px !important;
  }

  .text-title-hero {
    font-size: 60px !important;
    line-height: 60px !important;
  }

  .text-subtitle-hero {
    font-size: 60px !important;
    line-height: 60px !important;
  }

  .text-slogan-hero {
    line-height: 28px !important;
    font-size: 22px !important;
    padding-right: 18px;
  }
}

/*### Laptop Screen CSS Here ### */
@media screen and (max-width: 1024px) {
  .subtitle-container-hero {
    margin-left: 180px !important;
    margin-top: 20px !important;
  }
}

/*### Tablet Screen CSS Here ### */
@media screen and (max-width: 768px) {
  .text-container-hero {
    margin-left: 30px !important;
  }

  .subtitle-container-hero {
    margin-left: 80px !important;
    margin-top: 20px !important;
  }

  .text-title-hero {
    font-size: 50px !important;
    line-height: 50px !important;
  }

  .text-subtitle-hero {
    font-size: 50px !important;
    line-height: 50px !important;
  }

  .text-slogan-hero {
    font-size: 20px !important;
  }
}

/*### Phone Screen CSS Here ### */
@media screen and (max-width: 480px) {
  .text-container-hero {
    margin-left: 20px !important;
  }

  .subtitle-container-hero {
    margin-left: 80px !important;
    margin-top: 20px !important;
  }

  .text-title-hero {
    font-size: 40px !important;
    line-height: 40px !important;
  }

  .text-subtitle-hero {
    font-size: 40px !important;
    line-height: 40px !important;
  }
}
