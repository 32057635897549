.hover-underline-animation {
  display: inline-block;
  position: relative;
  color: #41d2bf;
}

.hover-underline-animation:after {
  content: "";
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: #41d2bf;
  transform-origin: bottom right;
  transition: transform 0.25s ease-out;
}

.hover-underline-animation:hover:after {
  transform: scaleX(1);
  transform-origin: bottom left;
}

.fill:hover {
  color: whitesmoke;
}

.fill:before {
  content: "";
  position: absolute;
  background: #41d2bf;
  bottom: 0;
  left: 0;
  right: 0;
  top: 100%;
  z-index: -1;
  -webkit-transition: top 0.09s ease-in;
  transition: top 0.09s ease-in;
}

.fill:hover:before {
  top: 0;
}

.logo-burger-container {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  align-content: center;
}

.logo-burger {
  width: 200px;
}

/*### Big Screen CSS Here ### */

.language-icon {
  font-size: 30px !important;
  margin-left: 50px !important;
  font-weight: 100;
  opacity: 0.8;
}

.navbar-text {
  font-weight: 400;
  letter-spacing: 0.13em;
  font-size: 20px !important;
  line-height: 35px !important;
}

.navbar-logo {
  width: 150px !important;
}

.navbar-icon {
  color: #41d2bf;
  font-size: 40px !important;
}

@media screen and (max-width: 1440px) {
  .navbar-text {
    font-size: 16px !important;
    line-height: 28px !important;
  }

  .fill-nav {
    font-size: 12px !important;
  }

  .language-icon {
    font-size: 25px !important;
    margin-left: 10px !important;
  }
}

@media screen and (max-width: 1200px) {
  .navbar-text {
    font-size: 13px !important;
    line-height: 22px !important;
  }

  .fill-nav {
    font-size: 10px !important;
  }

  .language-icon {
    font-size: 25px !important;
    margin-left: 10px !important;
  }

  .navbar-logo {
    width: 100px !important;
  }
}

@media screen and (max-width: 1024px) {
  .language-icon {
    margin-left: 0px !important;
  }
}

@media screen and (max-width: 768px) {
  .navbar-icon {
    font-size: 30px !important;
  }
}

@media only screen and (max-width: 480px) {
  .MuiDrawer-paper {
    width: 100% !important;
  }

  .logo-burger {
    width: 300px;
  }
}
